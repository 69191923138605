import React from 'react';
import cx from 'classnames';
import Link from 'next/link';

import Clickable from '../../components/Clickable';
import styles from './MenuItem.module.css';

interface MenuItemProps {
  href: string;
  label: string | React.ReactNode;
  onClick?: () => boolean;
  className?: string;
}

const MenuItem: React.FC<MenuItemProps> = ({ href, className, label, onClick, ...rest }) => (
  <li className={cx(styles.navItem, className)} {...rest}>
    <Link href={href} passHref>
      <Clickable className={styles.link} onClick={onClick}>
        {label}
      </Clickable>
    </Link>
  </li>
);

export default MenuItem;
