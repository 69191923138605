import React from 'react';
import { parseDjediLinks } from 'utils';

import { DjediNodeState } from '../../../../types/DjediNode';

type Item = {
  href: string;
  text: string;
};

/**
 * Renders a list of links from a Djedi Node.
 * @param links - A Djedi Node that contains a list of links.
 * @param render - A function that renders a link item.
 */

const LinkItems: React.FC<{
  links: React.ReactElement;
  render: (item: Item, index: number) => React.ReactElement;
}> = ({ links, render }) => {
  return React.cloneElement(links, {
    edit: false,
    render: (state: DjediNodeState) => {
      if (state.type !== 'success') {
        return null;
      }

      return parseDjediLinks(state.content).map((item, index) => render(item as Item, index));
    },
  });
};

export default LinkItems;
