import React from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';

import { asPathWithoutHash } from '../utils';
import { hashesType } from '../utils/constants';

type HashLinkProps = {
  href?: string;
  hash?: string | hashesType;
  clear?: boolean;
  children: React.ReactNode;
};

const HashLink: React.FC<HashLinkProps> = ({
  href,
  hash = undefined,
  clear,
  children,
  ...props
}) => {
  const { asPath } = useRouter();
  const uri = asPathWithoutHash(asPath);

  return (
    <Link href={clear ? uri : `${href ? href : uri}${hash}`} passHref scroll={false} {...props}>
      {children}
    </Link>
  );
};

export default HashLink;
