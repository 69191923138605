/*
 * NOTE: the Logo component has several assumptions about the structure of logo SVGs:
 * 1. Every SVG should contain a 'viewBox' attribute.
 * 2. SVGs should not contain 'width' or 'height' attributes.
 * 3. For SVGs with elements that are to be dynamically colored (usually only text), no 'fill' attribute should be present for that specific path.
 */

import React, { FC, FunctionComponent, SVGProps, useContext } from 'react';
import getConfig from 'next/config';
import Link from 'next/link';

import ROUTES from '../../../routes';
import AppContext from '../../containers/AppContext';
import EddabookingSVG from '../../icons/eddabooking.svg';
import EddalabsSVG from '../../icons/eddalabs.svg';
import EndometrioseguidenNoSVG from '../../icons/endometrioseguiden_no.svg';
import EndometrioseguidenSeSVG from '../../icons/endometrioseguiden_se.svg';
import NiptskolenSVG from '../../icons/niptskolen.svg';
import OsloUniversitetSVG from '../../icons/OUS.svg';
import { Market, OUS_MARKETS } from '../../utils/constants';
import Clickable from '../Clickable';
import styles from './Logo.module.css';

type ExtendedSVGElementProps = SVGProps<SVGSVGElement>;

const marketLogo: Record<Market, FC<ExtendedSVGElementProps>> = {
  [Market.PARTNER]: NiptskolenSVG,
  [Market.ENDOMETRIOSIS]: EndometrioseguidenNoSVG,
  [Market.SE]: EddalabsSVG,
  [Market.NO]: EddabookingSVG,
  [Market.OUS]: OsloUniversitetSVG,
  [Market.OUS_VIDEO]: OsloUniversitetSVG,
  [Market.OUS_NIPT]: OsloUniversitetSVG,
  [Market.OUS_ULTRASOUND]: OsloUniversitetSVG,
};

/**
 * Function to get the logo based on the current market and app URL.
 * If the app URL is 'https://endometrioseguiden.se', it will return the 'EndometrioseguidenSeSVG' logo.
 * Otherwise, it will return the logo for the current market.
 *
 * @param appUrl - The URL of the app.
 * @param currentMarket - The current market.
 * @returns The logo component to display.
 */
const getLogoForCurrentMarket = (
  appUrl: string,
  currentMarket: Market,
): FC<ExtendedSVGElementProps> => {
  // NOTE: the ENDOMETRIOSIS market has different urls for the norwegian and swedish versions,
  // so we need both the url and the currentMarket to determine the correct logo
  if (appUrl === 'https://endometriosguiden.se') {
    return EndometrioseguidenSeSVG;
  }
  return marketLogo[currentMarket];
};

/**
 * The `Logo` component displays the appropriate logo SVG based on the current market context and app URL.
 *
 * The component determines the correct logo SVG to display by calling the `getLogoForCurrentMarket` function,
 * which returns the logo SVG for the current market. If the app URL is 'https://endometrioseguiden.se',
 * the 'EndometrioseguidenSeSVG' logo will be returned. In all other cases, the function will return the logo
 * SVG associated with the current market.
 *
 * @component
 * @param props - The properties passed to the component.
 * @returns The logo to display.
 */
const Logo: FunctionComponent<ExtendedSVGElementProps> = props => {
  const { currentMarket } = useContext(AppContext);
  const { APP_URL } = getConfig().publicRuntimeConfig;

  const CurrentLogo = getLogoForCurrentMarket(APP_URL, currentMarket);
  let href = ROUTES.HOME.href;
  if (OUS_MARKETS.includes(currentMarket) && currentMarket !== Market.OUS) {
    href = '/dashboard';
  }

  return (
    <Link href={href} passHref>
      <Clickable aria-label="Startsida">
        <CurrentLogo className={styles.logo} fill="black" {...props} />
      </Clickable>
    </Link>
  );
};

export default Logo;
