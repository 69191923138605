import React from 'react';
import cx from 'classnames';
import Clickable from 'components/Clickable';
import HashLink from 'components/HashLink';
import AppContext from 'containers/AppContext';
import { HASHES } from 'utils/constants';

import AccountSVG from '../../../icons/circular_face.svg';
import styles from './LoginButton.module.css';

export type LoginButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement>;

const LoginButton: React.FC<LoginButtonProps> = ({ className, children, ...props }) => {
  const { loginState } = React.useContext(AppContext);
  return (
    <HashLink hash={loginState.loggedIn ? HASHES.EDIT_PROFILE : HASHES.LOGIN}>
      <Clickable className={cx(styles.root, className)} {...props}>
        <AccountSVG className={styles.svg} />
        <span>{children}</span>
      </Clickable>
    </HashLink>
  );
};

export default LoginButton;
