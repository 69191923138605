import React, { FunctionComponent } from 'react';
import Block from 'components/Block';
import Clickable from 'components/Clickable';
import Container from 'components/Container';
import LinkItems from 'components/LinkItems';
import Logo from 'components/Logo';
import Typography from 'components/Typography';
import AppContext from 'containers/AppContext';
import { NAV } from 'djedi-nodes/generic';
import Link from 'next/link';
import { shouldFullscreenLogin } from 'utils';

import EddaSVG from '../../icons/edda.svg';
import styles from './Footer.module.css';

const Footer: FunctionComponent = () => {
  const { currentMarket } = React.useContext(AppContext);
  const fullscreenLogin = shouldFullscreenLogin(currentMarket);

  if (fullscreenLogin) {
    return (
      <Block background="white" component="footer" className={styles.topBorder}>
        <Container className={styles.centerAlign}>
          <EddaSVG height={30} width={60} />
        </Container>
      </Block>
    );
  }
  return (
    <Block background="black" component="footer">
      <Container className={styles.root}>
        <Logo fill="white" />
        <LinkItems
          links={NAV.FOOTER}
          render={(item, index) => {
            return (
              <Link href={item.href} passHref key={index}>
                <Clickable>
                  <Typography {...item} className={styles.item} block variant="link" color="white">
                    {item.text}
                  </Typography>
                </Clickable>
              </Link>
            );
          }}
        />
      </Container>
    </Block>
  );
};

export default Footer;
