import React, { FunctionComponent, ReactElement, ReactNode } from 'react';
import cx from 'classnames';
import Logo from 'components/Logo';
import AppContext from 'containers/AppContext';
import NODES from 'djedi-nodes/generic';
import { ForceNodes, Node } from 'djedi-react';
import { default as BookingNav } from 'eddalabs/blocks/Nav';
import BookingFooter from 'eddalabs/components/Footer';
import Head from 'next/head';
import { selectMarketComponent, shouldFullscreenLogin } from 'utils';

import { DjediNodeState } from '../../../types/DjediNode';
import Feature from '../../components/FeatureFlag';
import { ToggleableFeature } from '../../utils/FeatureFlag/constants';
import AdminNav from '../AdminNav';
import DefaultFooter from '../Footer';
import { default as DefaultNav, NavProps } from '../Nav';
import styles from './Layout.module.css';

export type LayoutProps = {
  name?: string;
  children?: ReactNode;
  title?: string | ReactElement;
  description?: string | ReactElement;
  NavProps?: NavProps;
  navShadow?: boolean;
  admin?: boolean;
  showLogo?: boolean;
};

const renderTitle: React.FC<DjediNodeState> = state => (
  <Title>{state.type === 'success' ? state.content : ''}</Title>
);

const renderDesc: React.FC<DjediNodeState> = state => {
  const content = state.type === 'success' ? state.content.trim() : '';
  return content !== '' ? (
    <Head>
      <meta name="description" content={content} />
      <meta property="og:description" content={content} />
    </Head>
  ) : null;
};

const Layout: FunctionComponent<LayoutProps> = ({
  children = null,
  title,
  description,
  NavProps,
  navShadow = false,
  admin = false,
  showLogo = false,
}) => {
  const { currentMarket } = React.useContext(AppContext);
  const fullscreenLogin = shouldFullscreenLogin(currentMarket);

  const Nav = selectMarketComponent(currentMarket, {
    NO: BookingNav,
    default: DefaultNav,
  });
  const Footer = selectMarketComponent(currentMarket, {
    NO: BookingFooter,
    default: DefaultFooter,
  });

  return (
    <>
      {title != undefined &&
        (typeof title === 'string' ? (
          <Title>{title}</Title>
        ) : (
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          title.type === Node &&
          React.cloneElement(title, {
            edit: false,
            render: renderTitle,
          })
        ))}
      {description != undefined &&
        (typeof description === 'string' ? (
          <Head>
            <meta name="description" content={description} />
            <meta property="og:description" content={description} />
          </Head>
        ) : (
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          description.type === Node &&
          React.cloneElement(description, {
            edit: false,
            render: renderDesc,
          })
        ))}

      {/** /-- SEO -- */}
      {/* Footer is smaller when fillscreenLogin is true, so we don't need to set a min-height */}
      <div className={cx(styles.root, !fullscreenLogin && styles.rootWithFooter)}>
        {showLogo && (
          <div className={styles.logo}>
            <Logo />
          </div>
        )}
        {admin ? (
          <Feature id={ToggleableFeature.CLINIC_ADMIN}>
            <AdminNav {...NavProps} />
          </Feature>
        ) : (
          <Nav {...NavProps} className={cx(navShadow && styles.shadow)} />
        )}
        <main className={styles.main}>{children}</main>
        <Feature id={ToggleableFeature.FOOTER}>
          <Footer />
        </Feature>
      </div>
    </>
  );
};

const Title: React.FC<{ children: string }> = ({ children }) => {
  const render = (state: DjediNodeState) => {
    const title = children.trim();
    const suffix = ` | ${state.content}`;
    const fullTitle =
      title === '' ? state.content : title.endsWith(suffix) ? title : `${title}${suffix}`;
    if (state.type === 'success') {
      return (
        <Head>
          <title>{fullTitle}</title>
          <meta property="og:title" content={fullTitle} />
        </Head>
      );
    }
    return null;
  };

  return (
    <>
      {React.cloneElement(NODES.BASE_PAGE_TITLE, {
        edit: false,
        render,
      })}
      <ForceNodes>{NODES.BASE_PAGE_TITLE}</ForceNodes>
    </>
  );
};

export default Layout;
