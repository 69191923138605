import React from 'react';
import cx from 'classnames';
import LoginButton from 'components/LoginButton';
import Logo from 'components/Logo';
import { useRouter } from 'next/router';

import MenuItem from '../MenuItem';
import styles from './AdminNav.module.css';
export interface NavProps {
  title?: React.ReactElement;
  color?: Color;
  fixed?: boolean;
  responsiveColor?: Color | undefined;
}

import NODES from 'djedi-nodes/clinic-admin';
import GENERIC_NODES from 'djedi-nodes/generic';

import ROUTES from '../../../routes';
import AppContext from '../../containers/AppContext';

const AdminNav: React.FC<NavProps> = () => {
  const { loginState } = React.useContext(AppContext);
  const router = useRouter();
  return (
    <>
      <nav className={cx(styles.root)}>
        <h1 className={styles.title}>
          <Logo height={32} />
        </h1>
        <div className={styles.rightPart}>
          <ul>
            {loginState.loggedIn && (
              <>
                <MenuItem
                  className={cx(styles.navItem, {
                    [styles.navItemActive]: router.route == ROUTES.CLINIC_ADMIN_TIMES.href,
                  })}
                  {...ROUTES.CLINIC_ADMIN_TIMES}
                  label={NODES.NAV.TIMES}
                />
                <MenuItem
                  className={cx(styles.navItem, {
                    [styles.navItemActive]: router.route == ROUTES.CLINIC_ADMIN_BOOKINGS.href,
                  })}
                  {...ROUTES.CLINIC_ADMIN_BOOKINGS}
                  label={NODES.NAV.BOOKINGS}
                />
                {/*<MenuItem*/}
                {/*  className={cx(styles.navItem, {*/}
                {/*    [styles.navItemActive]: router.route == ROUTES.CLINIC_ADMIN_PATIENTS.href,*/}
                {/*  })}*/}
                {/*  {...ROUTES.CLINIC_ADMIN_PATIENTS}*/}
                {/*  label={NODES.NAV.PATIENTS}*/}
                {/*/>*/}
                <MenuItem
                  className={cx(styles.navItem, {
                    [styles.navItemActive]: router.route == ROUTES.CLINIC_ADMIN.href,
                  })}
                  {...ROUTES.CLINIC_ADMIN}
                  label={NODES.NAV.CLINIC}
                />
              </>
            )}
          </ul>
          <LoginButton className={styles.loginButton}>
            {loginState.loggedIn
              ? `${loginState.user?.first_name} ${loginState.user?.last_name}`
              : GENERIC_NODES.LOGIN}
          </LoginButton>
        </div>
      </nav>
    </>
  );
};

export default AdminNav;
