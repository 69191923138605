import React from 'react';
import cx from 'classnames';
import Clickable from 'components/Clickable';

import Fullscreen from '../../components/Fullscreen';
import Swoosh from '../../components/Swoosh';
import CloseSVG from '../../icons/close.svg';
import styles from './NavigationOverlay.module.css';

type NavigationOverlayProps = {
  open: boolean;
  aboveMenu?: boolean;
  onClose?: React.MouseEventHandler<HTMLButtonElement>;
  color?: 'white' | 'pink' | 'black';
};

const NavigationOverlay: React.FC<NavigationOverlayProps> = ({
  open,
  color = 'white',
  aboveMenu = false,
  onClose,
  children,
}) => {
  const [visible, setVisible] = React.useState<boolean>(false);
  const transitionHandler = () => {
    setVisible(open);
  };

  React.useEffect(() => {
    if (!open) {
      setVisible(false);
    }
  }, [open]);

  return (
    <div
      className={cx(styles.menu, {
        [styles.pink]: color === 'pink',
        [styles.black]: color === 'black',
        [styles.menuVisible]: open,
        [styles.aboveMenu]: aboveMenu,
      })}
      onTransitionEnd={transitionHandler}
    >
      <span className={cx(styles.leftFiller)} />
      <div className={styles.bottomFiller}>
        <Swoosh from={color} to="transparent" />
      </div>
      <div className={cx(styles.menuInner, { [styles.innerVisible]: visible })}>{children}</div>
      {open && <Fullscreen />}
      {onClose && (
        <Clickable onClick={onClose} className={styles.closeButton}>
          <CloseSVG height={32} width={32} />
        </Clickable>
      )}
    </div>
  );
};

export default NavigationOverlay;
