import React from 'react';
import classNames from 'classnames';

import Button from '../../components/Button';
import AppContext from '../../containers/AppContext';
import styles from './LanguagePicker.module.css';

interface LanguagePickerProps {
  boxStyle?: boolean;
}

const LanguagePicker: React.FC<LanguagePickerProps> = ({ boxStyle = false }) => {
  const { languageSelectorOpen, setLanguageSelectorOpen } = React.useContext(AppContext);

  const containerClass = classNames({ [styles.languagePickerContainer]: !boxStyle });
  const buttonClass = classNames({ [styles.boxStyleButton]: boxStyle });
  const buttonColor = boxStyle ? 'black' : 'denim';

  const toggleLanguageSelector = () => {
    setLanguageSelectorOpen(!languageSelectorOpen);
  };

  return (
    <div className={containerClass}>
      <Button
        className={buttonClass}
        variant="text"
        color={buttonColor}
        onClick={toggleLanguageSelector}
      >
        Change language
      </Button>
    </div>
  );
};

export default LanguagePicker;
