import React, { FunctionComponent } from 'react';
import Block from 'components/Block';
import Clickable from 'components/Clickable';
import Container from 'components/Container';
import Logo from 'components/Logo';
import TrustpilotWidget from 'components/TrustpilotWidget';
import Typography from 'components/Typography';
import AppContext from 'containers/AppContext';
import { NAV } from 'djedi-nodes/generic';
import LinkItems from 'eddalabs/components/LinkItems';
import Link from 'next/link';
import { shouldFullscreenLogin } from 'utils';

import EddaSVG from '../../../icons/edda.svg';
import EddaEmailSVG from '../../../icons/eddalabs_email.svg';
import styles from './Footer.module.css';

const Footer: FunctionComponent = () => {
  const { currentMarket } = React.useContext(AppContext);
  const fullscreenLogin = shouldFullscreenLogin(currentMarket);

  if (fullscreenLogin) {
    return (
      <Block background="white" component="footer" className={styles.topBorder}>
        <Container className={styles.centerAlign}>
          <EddaSVG height={30} width={60} />
        </Container>
      </Block>
    );
  }
  return (
    <Block background="black" component="footer">
      <Container className={styles.root}>
        <Block padding="none" className={styles.logoSection}>
          <Logo fill="white" />
          <a href="mailto:hei@eddalabs.com">
            <EddaEmailSVG height={21} width={152} />
          </a>
        </Block>
        <Block padding="none" type="flex" className={styles.linksSection}>
          <div>
            <LinkItems
              links={NAV.FOOTER}
              render={(item, index) => {
                return (
                  <Link href={item.href} passHref key={index}>
                    <Clickable>
                      <Typography
                        {...item}
                        className={styles.item}
                        block
                        variant="link"
                        color="greysolo"
                      >
                        {item.text}
                      </Typography>
                    </Clickable>
                  </Link>
                );
              }}
            />
          </div>
          <TrustpilotWidget type="large" theme="dark" />
        </Block>
      </Container>
    </Block>
  );
};

export default Footer;
