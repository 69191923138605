import React from 'react';
import cx from 'classnames';
import Script from 'next/script';

import AppContext from '../../containers/AppContext';
import styles from './TrustpilotWidget.module.css';

// Typescript hinting for Trustpilot object
declare global {
  interface Window {
    Trustpilot: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  }
}

interface TrustpilotWidgetProps extends React.HTMLProps<HTMLOrSVGElement> {
  type?: 'small' | 'large';
  theme?: 'light' | 'dark';
  className?: string;
}

const TrustpilotWidget: React.FC<TrustpilotWidgetProps> = ({ type = 'small', theme = 'light' }) => {
  const { language } = React.useContext(AppContext);
  const ref = React.useRef(null);
  const norway = language == 'nb';
  const [loaded, setLoaded] = React.useState(false);

  const onLoad = React.useCallback(async () => {
    /**
     * If the component is rendered in multiple places, `onLoad` will called at different points
     * in the progress of loading the script. Therefore, we do some retries before loading the widget.
     */

    const maxRetries = 20;
    let retryIndex = 0;

    while (
      (typeof window === 'undefined' ||
        typeof window.Trustpilot === 'undefined' ||
        typeof ref.current === 'undefined') &&
      retryIndex < maxRetries
    ) {
      retryIndex++;

      await new Promise(resolve => setTimeout(resolve, 10));
    }

    if (typeof window.Trustpilot === 'undefined') {
      console.error('Failed loading Trustpilot widget');
      return;
    }

    window.Trustpilot.loadFromElement(ref.current, true);
    setLoaded(true);
  }, []);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  /* Root has to be a span, otherwise Trustpilot swallows the outer div and fucks up the html structure */
  return (
    <>
      <Script
        type="text/javascript"
        src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
        async
        strategy="afterInteractive"
        onLoad={onLoad}
      />
      <span
        className={cx(
          styles.root,
          loaded && styles.loaded,
          type === 'small' ? styles.small : styles.large,
        )}
      >
        <div
          ref={ref}
          className="trustpilot-widget"
          suppressHydrationWarning={true}
          data-locale={norway ? 'nb-NO' : 'en-US'}
          data-template-id={
            type === 'small' ? '5419b732fbfb950b10de65e5' : '53aa8807dec7e10d38f59f32'
          }
          data-businessunit-id="5ece742d6efff50001862da7"
          data-style-height={type === 'small' ? '24px' : '100px'}
          data-style-width={type === 'small' ? 'inherit' : '100%'}
          data-theme={theme}
        >
          <a
            href={
              norway
                ? 'https://no.trustpilot.com/review/eddalabs.com'
                : 'https://www.trustpilot.com/review/eddalabs.com'
            }
            target="trustpilot"
            rel="noopener"
          >
            Trustpilot
          </a>
        </div>
      </span>
    </>
  );
};

export default TrustpilotWidget;
