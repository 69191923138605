import React from 'react';
import MenuItem from 'blocks/MenuItem';
import NavigationOverlay from 'blocks/NavigationOverlay';
import cx from 'classnames';
import Clickable from 'components/Clickable';
import Container from 'components/Container';
import Feature from 'components/FeatureFlag';
import Inert from 'components/Inert';
import LanguagePicker from 'components/LanguagePicker';
import LinkItems from 'components/LinkItems';
import Logo from 'components/Logo';
import AppContext from 'containers/AppContext';
import { ApiContext } from 'contexts/ApiContext';
import NODES, { NAV } from 'djedi-nodes/generic';
import { ForceNodes } from 'djedi-react';
import LoginButton from 'eddalabs/components/LoginButton';
import { useRouter } from 'next/router';
import { logout } from 'reducers/login';
import { shouldFullscreenLogin } from 'utils';
import { Market } from 'utils/constants';
import { ToggleableFeature } from 'utils/FeatureFlag/constants';

import ROUTES from '../../../../routes';
import BookingMenuSVG from '../../../icons/booking_menu.svg';
import styles from './Nav.module.css';

export interface NavProps {
  title?: React.ReactElement;
  /** Whether the overlay menu should go above the menu. */
  overlayAboveMenu?: boolean;
  color?: Color;
  /** Represents {@linkcode classNames}.`nav` */
  className?: string;
  /**
   * Classes for the elements of the navigation bar.
   * - `fixedSize`: Specifies the height of the navigation bar when fixed.
   */
  classNames?: Partial<Record<'nav', string>>;
  /** Whether a backdrop sould be visible underneath the menu and above the main content when fixed. */
  backdrop?: boolean;
  /** Whether the backdrop is visible (defaults to the value of `backdrop`). */
  backdropVisible?: boolean;
  /** Called when `fixed` and `backdrop` is set true and the backdrop is clicked. */
  onClickBackdrop?: React.MouseEventHandler<HTMLDivElement>;
  responsiveColor?: Color | undefined;
  /** Every element in `rows` will represent a row in the `<nav>` element, where the
   * top row will contain the logo and hamburger menu button. */
  rows?: React.ReactElement | React.ReactElement[];
  /** Every element in `rows` will represent a row in the `<nav>` element, where the
   * top row will contain the logo and hamburger menu button. The row will also be contained
   * using the {@link Container} elements. */
  containedRows?: React.ReactNode | React.ReactNodeArray;
  /** Whether the hamburger menu button should be displayed. */
  displayHamburgerMenu?: boolean;
  /** Whether the navigation bar should be visible. */
  visible?: boolean;
}

const Nav: React.FC<NavProps> = ({
  title,
  overlayAboveMenu,
  className,
  classNames,
  backdrop = false,
  backdropVisible = backdrop,
  onClickBackdrop,
  rows = [],
  containedRows = [],
  displayHamburgerMenu = true,
  visible = true,
  ..._
}) => {
  const [open, setOpen] = React.useState<boolean>(false);
  const router = useRouter();
  const api = React.useContext(ApiContext);
  const { loginState, loginStateDispatch, currentMarket } = React.useContext(AppContext);
  const fullscreenLogin = shouldFullscreenLogin(currentMarket);
  const displayLoginButton = !fullscreenLogin && !loginState?.loggedIn;

  return (
    <>
      {backdrop && (
        <div
          onClick={onClickBackdrop}
          className={cx(styles.backdrop, backdropVisible && styles.backdropVisible)}
        />
      )}
      <div
        className={cx(styles.root, className, {
          [styles.withBackdrop]: backdrop,
        })}
      >
        {visible && (
          <nav
            className={cx(styles.row, styles.paddedRow, styles.topRow, classNames?.nav)}
            key="nav"
          >
            <div className={styles.flexGroup}>
              <Feature id={ToggleableFeature.HAMBURGER_MENU}>
                {displayHamburgerMenu && (
                  <Clickable className={styles.burger} onClick={() => setOpen(!open)}>
                    <BookingMenuSVG height={24} width={24} />
                  </Clickable>
                )}
              </Feature>
              <h1 className={styles.title}>
                {typeof title === 'string' ? (
                  title
                ) : typeof title === 'object' ? (
                  React.cloneElement(title, {
                    render(state) {
                      return state.type === 'success' ? state.content : null;
                    },
                  })
                ) : (
                  <Logo />
                )}
              </h1>
            </div>
            {displayLoginButton && <LoginButton />}
          </nav>
        )}
        {React.Children.map(rows, (row, i) => (
          <div className={cx(styles.row, styles.paddedRow)} key={'r' + i}>
            {row}
          </div>
        ))}
        {React.Children.map(containedRows, (row, i) => (
          <Container className={cx(styles.row)} key={'cr' + i}>
            {row}
          </Container>
        ))}
      </div>
      <NavigationOverlay
        aboveMenu={overlayAboveMenu}
        onClose={overlayAboveMenu ? () => setOpen(false) : undefined}
        open={open}
      >
        <Container fullHeight className={styles.menuContainer}>
          <ul className={styles.menuItemContainer}>
            <Inert active={!open}>
              <Feature id={ToggleableFeature.HOME_PAGE}>
                <MenuItem href="/" label={NODES.HOME} className={styles.menuItem} />
              </Feature>
              {loginState.loggedIn && (
                <>
                  <MenuItem
                    {...ROUTES.DASHBOARD}
                    label={NODES.DASHBOARD}
                    className={styles.menuItem}
                  />
                  <MenuItem {...ROUTES.SCHOOL} label={NODES.SCHOOL} className={styles.menuItem} />
                  {currentMarket !== Market.OUS_NIPT && currentMarket !== Market.OUS_ULTRASOUND && (
                    <MenuItem
                      {...ROUTES.PROFILE}
                      label={NODES.PROFILE}
                      className={styles.menuItem}
                    />
                  )}
                  {Boolean(loginState.user?.clinic_id) && (
                    <Feature id={ToggleableFeature.CLINIC_ADMIN}>
                      <MenuItem
                        {...ROUTES.CLINIC_ADMIN}
                        label={NODES.CLINIC_ADMIN}
                        className={styles.menuItem}
                      />
                    </Feature>
                  )}
                </>
              )}
              <Feature id={ToggleableFeature.ABOUT_PAGE}>
                <LinkItems
                  render={(item, i) => (
                    <MenuItem
                      key={i}
                      href={item?.href || ''}
                      label={item?.text || ''}
                      className={styles.menuItem}
                    />
                  )}
                  links={NAV.MAIN}
                />
              </Feature>
              {loginState.loggedIn &&
                currentMarket !== Market.OUS_NIPT &&
                currentMarket !== Market.OUS_ULTRASOUND &&
                [
                  {
                    href: '/',
                    label: NODES.LOG_OUT,
                    onClick: () => {
                      api.logout().then(() => {
                        router.push(ROUTES.HOME.href);
                        loginStateDispatch && loginStateDispatch(logout());
                      });
                      return false;
                    },
                  },
                ].map(({ href, label, onClick }, i) => (
                  <MenuItem
                    key={i}
                    label={label}
                    href={href}
                    onClick={onClick}
                    className={styles.menuItem}
                  />
                ))}
            </Inert>
          </ul>
          <Feature id={ToggleableFeature.LANGUAGE_PICKER}>
            <LanguagePicker />
          </Feature>
        </Container>
      </NavigationOverlay>
      <ForceNodes>{NODES}</ForceNodes>
    </>
  );
};

export default Nav;
