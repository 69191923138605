import React, { useEffect, useRef } from 'react';

export type InertProps = {
  active: boolean;
  children: React.ReactNode;
};

const Inert: React.FC<InertProps> = props => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    props.active ? ref.current?.setAttribute('inert', '') : ref.current?.removeAttribute('inert');
  }, [props.active]);

  return <div ref={ref}>{props.children}</div>;
};

export default Inert;
