import React from 'react';
import { md, Node } from 'djedi-react';

const pageName = 'Clinics/Admin';

const NODES = {
  NAV: {
    TIMES: <Node uri={`${pageName}/nav/times`}>Tider</Node>,
    BOOKINGS: <Node uri={`${pageName}/nav/bookings`}>Bestillinger</Node>,
    PATIENTS: <Node uri={`${pageName}/nav/patients`}>Pasienter</Node>,
    CLINIC: <Node uri={`${pageName}/nav/clinic`}>Klinikk</Node>,
  },
  CALENDAR: {
    CLOSED: <Node uri={`${pageName}/calendar/closed`}>Stengt</Node>,
    TIMES: <Node uri={`${pageName}/calendar/times`}>tider</Node>,
    CLOSED_NOTICE: (
      <Node uri={`${pageName}/calendar/closed/notice.md`}>{md`
###Stengt
Du kan endre de lukkede dagene under innstillinger
      `}</Node>
    ),
  },
  SAVE: <Node uri={`${pageName}/save`}>Lagre</Node>,
  CLOSE: <Node uri={`${pageName}/close`}>Lukk</Node>,
  GENERAL: {
    TITLE: <Node uri={`${pageName}/general/title`}>Om Klinikken</Node>,
    INTRO: (
      <Node uri={`${pageName}/general/intro`}>
        Her kan du endre navn, adresse og kontaktinformasjon for klinikken din.
      </Node>
    ),
    FIELDS: {
      NAME: {
        TITLE: <Node uri={`${pageName}/general/fields/name/title`}>Navn på klinikken</Node>,
        PLACEHOLDER: <Node uri={`${pageName}/general/fields/name/placeholder`}>Ange navnn</Node>,
      },
      ADDRESS: {
        HEADING: <Node uri={`${pageName}/general/fields/address/heading`}>Adresse</Node>,
        TITLE: <Node uri={`${pageName}/general/fields/address/title`}>Gateadresse</Node>,
        PLACEHOLDER: (
          <Node uri={`${pageName}/general/fields/address/placeholder`}>Angi adresse</Node>
        ),
      },
      POSTAL_CODE: {
        TITLE: <Node uri={`${pageName}/general/fields/postal_code/title`}>Post kode</Node>,
        PLACEHOLDER: (
          <Node uri={`${pageName}/general/fields/postal_code/placeholder`}>Angi post kode</Node>
        ),
      },
      CITY: {
        TITLE: <Node uri={`${pageName}/general/fields/city/title`}>Sted</Node>,
        PLACEHOLDER: <Node uri={`${pageName}/general/fields/city/placeholder`}>Angi sted</Node>,
      },
      CONTACT_HEADING: (
        <Node uri={`${pageName}/general/fields/contact/heading`}>Kontaktinformasjon</Node>
      ),
      PHONE: {
        TITLE: <Node uri={`${pageName}/general/fields/phone/title`}>Telefonnummer</Node>,
        PLACEHOLDER: (
          <Node uri={`${pageName}/general/fields/phone/placeholder`}>Angi telefonnummer</Node>
        ),
      },
      EMAIL: {
        TITLE: <Node uri={`${pageName}/general/fields/email/title`}>E-postadresse</Node>,
        PLACEHOLDER: (
          <Node uri={`${pageName}/general/fields/email/placeholder`}>Angi e-postadresse</Node>
        ),
      },
      NOTIFICATION_EMAIL: {
        TITLE: (
          <Node uri={`${pageName}/general/fields/email/notification_email/title`}>
            Notifierings e-postadresse
          </Node>
        ),
        PLACEHOLDER: (
          <Node uri={`${pageName}/general/fields/notification_email/placeholder`}>
            Angi notifierings e-postadresse
          </Node>
        ),
      },
    },
  },
  DEFAULTS: {
    TITLE: <Node uri={`${pageName}/defaults/title`}>Pris</Node>,
    INTRO: (
      <Node uri={`${pageName}/defaults/intro`}>
        Her kan du legge inn prisen for enhver tjeneste. Hvis en pris mangler, er den tjenesten ikke
        tilgjengelig for klinikken.{' '}
      </Node>
    ),
    FIELDS: {
      PRICE: {
        TITLE: <Node uri={`${pageName}/defaults/fields/price/title`}>Pris før</Node>,
        PLACEHOLDER: <Node uri={`${pageName}/defaults/fields/price/placeholder`}>Angi pris</Node>,
      },
    },
  },
  STAFF: {
    TITLE: <Node uri={`${pageName}/staff/title`}>Personale</Node>,
    INTRO: (
      <Node uri={`${pageName}/staff/intro`}>
        Her kan du legge til, endre eller fjerne personale som skal ha tilgang til din klinikkside.
      </Node>
    ),
    ADD_STAFF: <Node uri={`${pageName}/staff/add`}>Legg til personale</Node>,
    DELETE_STAFF: <Node uri={`${pageName}/staff/delete`}>Slett personale</Node>,
    FIELDS: {
      FIRST_NAME: {
        TITLE: <Node uri={`${pageName}/staff/fields/first_name/title`}>Førnavn</Node>,
        PLACEHOLDER: (
          <Node uri={`${pageName}/staff/fields/first_name/placeholder`}>Angi førnavn</Node>
        ),
      },
      LAST_NAME: {
        TITLE: <Node uri={`${pageName}/staff/fields/last_name/title`}>Etternavn</Node>,
        PLACEHOLDER: (
          <Node uri={`${pageName}/staff/fields/last_name/placeholder`}>Angi etternavn</Node>
        ),
      },
      SSN: {
        TITLE: <Node uri={`${pageName}/staff/fields/ssn/title`}>Personnummer</Node>,
        PLACEHOLDER: (
          <Node uri={`${pageName}/staff/fields/ssn/placeholder`}>Angi personnummer</Node>
        ),
      },
      EMAIL: {
        TITLE: <Node uri={`${pageName}/staff/fields/email/title`}>E-postadresse</Node>,
        PLACEHOLDER: (
          <Node uri={`${pageName}/staff/fields/email/placeholder`}>Angi e-postadresse</Node>
        ),
      },
    },
  },
};

export default NODES;
